<template>
  <el-dialog
    v-model="visible"
    :title="specRowMsg.code ? '修改套餐' : '新增套餐'"
    width="70%"
    :before-close="handleClose"
  >
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="specRowMsg"
      label-width="150px"
      ref="elForm"
      :rules="rules"
      v-loading="form.loading"
      :element-loading-text="form.loadText"
    >
      <div style="display: flex;">
        <div>
          <el-form-item label="套餐图片" prop="pic">
            <el-upload
              :accept="uploadRule.extensions"
              list-type="picture"
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="changeImage"
            >
              <img v-if="specRowMsg.pic" :src="specRowMsg.pic" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item>
        </div>
        <div style="flex: 1;">
          <el-form-item label="套餐名称" prop="name">
            <el-input v-model="specRowMsg.name" style="width:70%" placeholder="请填写组合名称" />
          </el-form-item>
          <el-form-item label="套餐规格">
            <el-input v-model="specRowMsg.spec" style="width:70%" placeholder="请填写规格" />
          </el-form-item>
        </div>
      </div>
      <div style="display: flex;">
        <div>
          <el-form-item label="指导价(元)" prop="price2">
            <div style="display: flex;">
              <el-input
                v-model="specRowMsg.price1"
                style="width: 30%; margin-right: 10px;"
                placeholder="支持2位小数"
              />～
              <el-input v-model="specRowMsg.price2" style="width: 30%; margin-left: 10px;" placeholder="支持2位小数" />
            </div>
          </el-form-item>
          <el-form-item label="最小单位" prop="unit">
            <el-select v-model="specRowMsg.unit" placeholder="请选择最小单位" filterable>
              <el-option :label="item.name" :value="item.name" v-for="(item, index) in units" :key="index" />
            </el-select>
          </el-form-item>
        </div>
        <div style="flex: 1;">
          <el-form-item label="最低毛利率(%)" prop="minimum">
            <el-input v-model="specRowMsg.minimum" style="width:70%" placeholder="支持2位小数" />
          </el-form-item>
          <el-form-item label="起售数量" prop="miniNumber">
            <el-input-number
              v-model="specRowMsg.miniNumber"
              style="width:70%"
              :precision="0"
              :min="1"
              :max="100"
              :controls="false"
              placeholder="请输入起售数量"
            />
          </el-form-item>
        </div>
      </div>
      <el-form-item label="选择套餐场景" prop="isConsumeFree">
        <el-radio-group v-model="specRowMsg.isConsumeFree">
          <el-radio :label="0">套餐</el-radio>
          <el-radio :label="1">套免</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="投放渠道" prop="channel" v-show="specRowMsg.isConsumeFree === 0">
        <el-checkbox-group v-model="specRowMsg.channel">
          <el-checkbox :label="item.name" size="large" v-for="item in channelList">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="套餐内容"
        prop="comboType"
        v-show="
          specRowMsg.isConsumeFree === 0 && specRowMsg.channel.length == 1 && specRowMsg.channel[0] == 'POS收银机'
        "
      >
        <el-radio-group v-model="specRowMsg.comboType">
          <el-radio :label="1">商品</el-radio>
          <el-radio :label="2">关联券包</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="券包"
        prop="couponPackageVOList"
        class="table-el"
        v-if="
          specRowMsg.isConsumeFree === 0 &&
            specRowMsg.channel.length == 1 &&
            specRowMsg.channel[0] == 'POS收银机' &&
            specRowMsg.comboType == 2
        "
      >
        <el-button @click="addPC(specRowMsg.couponPackageVOList)">添加券包</el-button>
        <el-card
          style=" margin-top: 10px; width:90%;overflow-x: auto;"
          v-if="specRowMsg.couponPackageVOList && specRowMsg.couponPackageVOList.length !== 0"
        >
          <el-table :data="specRowMsg.couponPackageVOList" style="width: 100%">
            <el-table-column label="券包信息" align="center">
              <template #default="scope">
                <div>
                  <p style="text-align: left;">券包编号：{{ scope.row.code }}</p>
                  <p style="text-align: left;">券包名称：{{ scope.row.name }}</p>
                  <p style="text-align: left;">包含优惠券种类：{{ scope.row.containCount }}</p>
                  <p style="text-align: left;">
                    个人限买数量：{{ scope.row.limitNum == -1 ? '不限制' : scope.row.limitNum }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <el-button type="text" @click="deletePC(scope.row.code)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-form-item>

      <el-form-item
        label="必选商品"
        prop="requiredContents"
        class="table-el"
        v-if="
          !(
            specRowMsg.isConsumeFree === 0 &&
            specRowMsg.channel.length == 1 &&
            specRowMsg.channel[0] == 'POS收银机' &&
            specRowMsg.comboType == 2
          )
        "
      >
        <el-button @click="addShops(specRowMsg.requiredContents)">选择商品</el-button>
        <el-card
          style=" margin-top: 10px; width:90%;overflow-x: auto;"
          v-if="specRowMsg.requiredContents && specRowMsg.requiredContents.length !== 0"
        >
          <el-table :data="specRowMsg.requiredContents" style="width: 100%">
            <el-table-column label="序号" align="center" type="index" />
            <el-table-column prop="name" label="商品名称" align="center" width="150" />
            <el-table-column label="商品图片">
              <template #default="scope" align="center">
                <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
              </template>
            </el-table-column>
            <el-table-column prop="spec" label="规格" />
            <el-table-column prop="price" label="指导价(元)" />
            <el-table-column prop="unit" label="最小单位" />
            <el-table-column prop="productTypeName" label="商品类型" />
            <el-table-column label="数量" align="center" width="200">
              <template #default="scope">
                <el-input-number v-model="scope.row.count" :min="1" :max="1000" />
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <el-button type="text" @click="deleteShop(scope.row.code)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-form-item>
      <el-form-item
        label="可选方案"
        prop="optionContents"
        class="table-el"
        v-if="
          !(
            specRowMsg.isConsumeFree === 0 &&
            specRowMsg.channel.length == 1 &&
            specRowMsg.channel[0] == 'POS收银机' &&
            specRowMsg.comboType == 2
          )
        "
      >
        <el-button @click="addOptionContents">新增可选方案</el-button>
        <el-card
          style=" margin-top: 10px;width: 90%;"
          v-if="specRowMsg.optionContents && specRowMsg.optionContents.length !== 0"
          v-for="(optionContentItem, index) in specRowMsg.optionContents"
        >
          <el-form :inline="true" :model="optionContentItem" ref="elForm1" :rules="rules1">
            <el-form-item prop="name">
              <el-input v-model="optionContentItem.name" placeholder="可选组名称" />
            </el-form-item>
            <el-form-item prop="type">
              <el-radio-group v-model="optionContentItem.type">
                <el-radio :label="1">商品数量</el-radio>
                <el-radio :label="2">方案选择</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-input-number v-model="optionContentItem.selectCount" placeholder="请输入数量" :controls="false" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="addScheme(optionContentItem)">选择商品</el-button>
              <el-button type="primary" @click="deleteOptionContents(index)">删除方案</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="optionContentItem.products" style="width: 100%">
            <el-table-column label="序号" align="center" type="index" />
            <el-table-column prop="name" label="商品名称" align="center" width="150" />
            <el-table-column label="商品图片">
              <template #default="scope" align="center">
                <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
              </template>
            </el-table-column>
            <el-table-column prop="spec" label="规格" />
            <el-table-column prop="price" label="指导价(元)" />
            <el-table-column prop="unit" label="最小单位" />
            <el-table-column prop="productTypeName" label="商品类型" />
            <el-table-column :label="optionContentItem.type == 1 ? '封顶数量' : '固定数量'" align="center" width="250">
              <template #default="scope">
                <el-input-number
                  v-model="scope.row.count"
                  :min="1"
                  @change="changeNumber(optionContentItem, optionContentItem.products, scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <el-button type="text" @click="deleteOptionContentItem(optionContentItem.products, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-form-item>
      <el-form-item label="下发现存门店">
        <FilterShop :storeData="specRowMsg.storeCodeList" @changeStore="changeStore" />
      </el-form-item>
      <el-form-item label="下发将来门店" prop="isSales">
        <el-radio-group v-model="specRowMsg.isSales">
          <el-radio :label="1">自动开启售卖</el-radio>
          <el-cascader
            ref="casRef"
            v-model="specRowMsg.isSalesRegion"
            :options="form.shopLists"
            clearable
            :show-all-levels="false"
            collapse-tags
            collapse-tags-tooltip
            filterable
            :props="areaProps"
            style="margin-right:10px"
          ></el-cascader>
          <el-radio :label="0">禁止自动售卖</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否设为推荐">
        <el-switch v-model="specRowMsg.isRecommend" />
      </el-form-item>
      <el-form-item label="是否计入低消">
        <el-switch v-model="specRowMsg.isMinConsume" />
      </el-form-item>

      <el-form-item label="售卖状态" prop="isShelves">
        <el-radio-group v-model="specRowMsg.isShelves">
          <el-radio :label="1">上架</el-radio>
          <el-radio :label="0">下架(不论是否有库存，均下架停止售卖)</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="供货状态" prop="isSupply">
        <el-radio-group v-model="specRowMsg.isSupply">
          <el-radio :label="1">正常供货</el-radio>
          <el-radio :label="0">停止供货(现有库存，售完为止)</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="套餐产品介绍" prop="description">
        <el-input v-model="specRowMsg.description" placeholder="请输入套餐产品介绍" />
      </el-form-item>
      <el-form-item label="套餐备注">
        <el-input v-model="specRowMsg.remarks" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 10px;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;" :disabled="!isEdit">{{
        isEdit ? '确定' : '确定'
      }}</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
    <SelectShops
      v-if="form.addProductVisible"
      :type="form.type"
      :dialogVisible="form.addProductVisible"
      @update-dialogVisible="updateAddProductVisible"
      :hasPros="form.hasPros"
    ></SelectShops>
    <SelectPC
      v-if="form.addPlaceCardVisible"
      :dialogVisible="form.addPlaceCardVisible"
      @update-dialogVisible="updateAddPlaceCard"
      :hasPros="form.hasPros"
    ></SelectPC>
  </el-dialog>
</template>

<script>
import commonApi from '@/api'
import _ from 'lodash'
import FilterShop from '../../component/filter-shop.vue'
import { headers, client } from '../../../../lin/util/uploadImg.js'
import { reactive, defineComponent, computed, ref, toRaw, onMounted, nextTick } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import SelectShops from './SelectShops.vue'
import SelectPC from '../../component/selectPlaceCard.vue'
import { uploadFileMixin, uploadfileRuleMixin } from '@/mixins/uploadFile.js'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    specRowMsg: Object,
    units: Array,
    channelList: Array,
    isEdit: Boolean,
  },
  components: {
    SelectShops,
    FilterShop,
    SelectPC,
  },
  setup(props, ctx) {
    const uploadRule = reactive({
      businessType: 'COMMODITY_COMBO',
      extensions: '',
    })
    onMounted(() => {
      uploadfileRuleMixin({ businessType: uploadRule.businessType }).then(res => {
        uploadRule.extensions = res.extensions
      })
    })
    const form = reactive({
      loadText: '正在火速加载数据...',
      img1: [],
      searchShop: '',
      addProductVisible: false,
      addPlaceCardVisible: false,
      type: 1, //1:表示必选 2:表示可选
      optionContentItem: {}, //可选方案
      shopLists: [],
      geoCode: [],
      loading: false,
      loading1: false,
      hasPros: [], // 已经有的商品
    })

    onMounted(() => {
      getArea()
    })
    /**
     * 改变门店
     * @param {*} data
     * isAll:是否全选 true全选 false全不选：需要将之前加的数据删除
     */
    const changeStore = ({ isAll, data = [], isOneClick }) => {
      if (isOneClick) {
        props.specRowMsg.storeCodeList = data
        return
      }
      if (isAll) {
        data.forEach(item => {
          let index = props.specRowMsg.storeCodeList.findIndex(i => i === item)
          if (index === -1) {
            props.specRowMsg.storeCodeList.push(item)
          }
        })
      } else {
        data.forEach(item => {
          let index = props.specRowMsg.storeCodeList.findIndex(i => i === item)
          props.specRowMsg.storeCodeList.splice(index, 1)
        })
      }
    }

    const areaProps = reactive({
      label: 'name',
      value: 'code',
      children: 'children',
      checkStrictly: true,
      multiple: true,
    })
    //获取区域数据
    const getArea = () => {
      const getTreeData = data => {
        for (var i = 0; i < data.length; i++) {
          if (data[i].children.length < 1) {
            data[i].children = undefined
          } else {
            getTreeData(data[i].children)
          }
        }
        return data
      }
      commonApi.getNewArea().then(res => {
        form.shopLists = getTreeData(res.data)
      })
    }
    const casRef = ref()
    const checked1 = ref(false)

    const visible = computed(() => {
      return props.dialogVisible
    })

    const changeNumber = (optionContentItem, item = [], row) => {
      return parseInt(optionContentItem.selectCount)
    }

    const addShops = hasPros => {
      form.hasPros = _.cloneDeep(hasPros || [])
      form.type = 1
      form.addProductVisible = true
    }

    const addPC = hasPros => {
      form.hasPros = _.cloneDeep(hasPros || [])
      form.addPlaceCardVisible = true
    }

    const elForm1 = ref(null)
    const rules1 = reactive({
      type: [{ required: true, message: '请选择方案', trigger: 'blur' }],
      name: [{ required: true, message: '请填写方案名', trigger: 'blur' }],
    })

    const addScheme = async optionContentItem => {
      if (optionContentItem.type) {
        if (optionContentItem.type === 1 && !optionContentItem.selectCount) {
          ElMessage.info('请先填写数量')
          return
        }
        form.type = 2
        form.addProductVisible = true
        form.optionContentItem = optionContentItem
        form.hasPros = optionContentItem.products
      } else {
        ElMessage.info('请先选择方案类型')
      }
    }

    //最多三个可选方案，每个方案最多 10 种
    const addOptionContents = () => {
      if (props.specRowMsg.optionContents.length === 3) {
        ElMessage.warning('可选方案最多支持3种')
        return
      }
      props.specRowMsg.optionContents.push({ name: `方案${props.specRowMsg.optionContents.length + 1}`, products: [] })
    }

    const deleteOptionContents = index => {
      props.specRowMsg.optionContents.splice(index, 1)
    }

    const deleteOptionContentItem = (deleteOptionContents, item) => {
      let index = deleteOptionContents.findIndex(item1 => item1.code === item.code)
      deleteOptionContents.splice(index, 1)
    }

    const deleteShop = item => {
      let index = props.specRowMsg.requiredContents.findIndex(item1 => item1.code === item)
      props.specRowMsg.requiredContents.splice(index, 1)
    }

    const deletePC = item => {
      let index = props.specRowMsg.couponPackageVOList.findIndex(item1 => item1.code === item)
      props.specRowMsg.couponPackageVOList.splice(index, 1)
    }

    const updateAddProductVisible = ({ update, selectSkus = [], isUpdate }) => {
      form.addProductVisible = update
      if (isUpdate) {
        if (form.type === 1) {
          props.specRowMsg.requiredContents = selectSkus
        } else {
          form.optionContentItem.products = selectSkus
        }
      }
    }
    const updateAddPlaceCard = ({ update, selectSkus = [], isUpdate }) => {
      form.addPlaceCardVisible = update
      if (isUpdate) {
        if (form.type === 1) {
          props.specRowMsg.couponPackageVOList = selectSkus
        } else {
          form.optionContentItem.products = selectSkus
        }
      }
    }

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const casRef1 = ref()
    const changeImage = async file => {
      file.fileName = file.file.name

      const res = await uploadFileMixin(
        file,
        uploadRule.businessType,
        () => {
          form.loadText = '正在火速上传图片...'
          form.loading = true
          form.img1.push(file)
          props.specRowMsg.pic = ''
        },
        fileUrl => {
          form.loading = false
          props.specRowMsg.pic = fileUrl
        },
      )
    }

    const add = params => {
      API.createComboInfo(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('保存成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }
    const update = params => {
      API.updateComboInfo(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('更新成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }

    const checkprice = (rule, value, callback) => {
      if (!props.specRowMsg.price1 || !props.specRowMsg.price2) {
        return callback(new Error('指导价必填'))
      }

      if (isNaN(toRaw(props.specRowMsg.price1)) || isNaN(toRaw(props.specRowMsg.price1))) {
        return callback(new Error('指导价必须是数字'))
      }
      if (parseInt(toRaw(props.specRowMsg.price1)) > parseInt(toRaw(props.specRowMsg.price2))) {
        return callback(new Error('指导价后面必须大于前面'))
      } else {
        callback()
      }
    }

    const checkProject = (rule, value, callback) => {
      if (props.specRowMsg.requiredContents.length === 0 && props.specRowMsg.optionContents.length === 0) {
        return callback(new Error('必选商品和可选方案组不能同时为空'))
      } else {
        callback()
      }
    }

    const checkMiniNum = (rule, value, callback) => {
      if (!props.specRowMsg.minimum) {
        callback()
      } else if (!/^[0-9]{1,2}(\.[0-9]{0,2})?$/.test(toRaw(props.specRowMsg.minimum))) {
        return callback(new Error('最低毛利率是数字，且最多保留两位'))
      } else {
        callback()
      }
    }

    const checkCouponPackageVOList = (rule, value, callback) => {
      console.log(props.specRowMsg)

      if (
        props.specRowMsg.isConsumeFree === 0 &&
        props.specRowMsg.channel.length == 1 &&
        props.specRowMsg.channel[0] == 'POS收银机' &&
        props.specRowMsg.comboType == 2 &&
        props.specRowMsg.couponPackageVOList.length === 0
      ) {
        return callback(new Error('券包不能为空'))
      } else {
        callback()
      }
    }
    const strlen = str => {
      if (!str) return 0

      let len = 0
      for (let i = 0; i < str.length; i++) {
        const code = str.codePointAt(i)

        if (!code) continue

        // ASCII字符占1个字节
        if (code <= 0x007f) {
          len += 1
        }
        // 汉字和其他Unicode字符占2个字节
        else if (code <= 0xffff) {
          len += 2
        }
        // emoji和其他扩展字符占4个字节
        else {
          len += 2
          i++ // Skip one loop since emoji uses two UTF-16 chars
        }
      }
      console.log(len)

      return len
    }

    const checkName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写套餐名称'))
      } else if (strlen(value) > 40) {
        callback(new Error('套餐名称不能超过20个字'))
      } else {
        callback()
      }
    }
    const checkDescription = (rule, value, callback) => {
      if (strlen(value) > 26) {
        callback(new Error('套餐产品介绍不能超过13个字'))
      } else {
        callback()
      }
    }
    const rules = reactive({
      pic: [{ required: true, message: '请上传套餐图片', trigger: 'blur' }],
      name: [{ trigger: 'blur', validator: checkName }],
      description: [{ trigger: 'blur', validator: checkDescription }],
      spec: [{ required: true, message: '请填写套餐规格', trigger: 'blur' }],
      unit: [{ required: true, message: '请选择最小单位', trigger: 'blur' }],
      miniNumber: [{ required: true, message: '请填写起售数量', trigger: 'blur' }],
      unitLay: [{ required: true, message: '请选择进货单位', trigger: 'blur' }],
      caseSpec: [{ required: true, message: '请填写进货比率', trigger: 'blur' }],
      price2: [{ required: true, trigger: 'blur', validator: checkprice }],
      channel: [{ required: true, message: '请选择投放渠道', trigger: 'blur' }],
      isSales: [{ required: true, message: '请选择下发将来门店方式', trigger: 'blur' }],
      storeCodeList: [{ required: true, trigger: 'blur', message: '请选择下发现存门店' }],
      requiredContents: [{ trigger: 'blur', validator: checkProject }],
      optionContents: [{ trigger: 'blur', validator: checkProject }],
      isShelves: [{ required: true, message: '请选择售卖状态', trigger: 'blur' }],
      isSupply: [{ required: true, message: '请选择供货状态', trigger: 'blur' }],
      minimum: [{ required: false, trigger: 'blur', validator: checkMiniNum }],
      couponPackageVOList: [{ trigger: 'blur', validator: checkCouponPackageVOList }],
    })

    const elForm = ref(null)

    const checkCardStore = async () => {
      let storeSet = new Set()
      props.specRowMsg.couponPackageVOList.forEach(a => {
        storeSet.add(a.attemptStore)
      })
      if (storeSet.size !== 1) {
        ElMessage.warning('添加的优惠券适用门店必须一致')
        return Promise.reject('error')
      }
    }

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate(async (valid, fields) => {
        for (let i = 0; i < props.specRowMsg.optionContents.length; i++) {
          let item = props.specRowMsg.optionContents[i]
          if (item.type == 2) {
            if (!item.selectCount || item.selectCount == 0) {
              ElMessage.info(`${item.name}的数量必须填写且大于0`)
              return
            }
            if (item.selectCount < 0) {
              ElMessage.info(`${item.name}的商品数量不能是负数`)
              return
            }
            let productount = item.products.length
            if (productount < item.selectCount) {
              ElMessage.info(`${item.name}的商品数量必须小于或等于方案数量`)
              return
            }
          } else if (item.type == 1) {
            if (item.selectCount < 0) {
              ElMessage.info(`${item.name}的数量不能是负数`)
              return
            }
            if (!item.selectCount || item.selectCount == 0) {
              ElMessage.info(`${item.name}的数量必须填写且大于0`)
              return
            }
          }
        }
        let hasPC =
          props.specRowMsg.isConsumeFree === 0 &&
          props.specRowMsg.channel.length == 1 &&
          props.specRowMsg.channel[0] == 'POS收银机' &&
          props.specRowMsg.comboType == 2

        if (valid) {
          form.loadText = '正在火速处理数据...'
          form.loading = true
          let params = {
            ...props.specRowMsg,
            price: toRaw(props.specRowMsg.price1) + '~' + toRaw(props.specRowMsg.price2),
            channel: props.specRowMsg.channel.join(','),
            isMinConsume: props.specRowMsg.isMinConsume ? 1 : 0,
            isRecommend: props.specRowMsg.isRecommend ? 1 : 0,
            isStock: props.specRowMsg.isStock ? 1 : 0,
            isSalesRegion: casRef.value.getCheckedNodes().map(item => item.data.code),
            storeCodeList: Array.from(new Set(props.specRowMsg.storeCodeList)),
            couponPackageCodeList: props.specRowMsg.couponPackageVOList?.map(a => a.code),
          }
          params.optionContents.forEach(item => {
            item.totalCount = item.products.length
          })
          if (hasPC) {
            params.optionContents = []
            params.requiredContents = []
          } else {
            params.couponPackageCodeList = []
          }
          if (Reflect.has(props.specRowMsg, 'code')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }

    return {
      deleteOptionContentItem,
      deleteOptionContents,
      deleteShop,
      deletePC,
      checked1,
      form,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      changeImage,
      rules,
      elForm,
      elForm1,
      rules1,
      addShops,
      addScheme,
      updateAddProductVisible,
      updateAddPlaceCard,
      addPC,
      addOptionContents,
      changeNumber,
      handleSubmit,
      areaProps,
      casRef,
      casRef1,
      changeStore,
      uploadRule,
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__content {
  width: 80%;
}
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 150px !important;
  }
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-input-number {
  width: 80%;
}
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 150px !important;
  }
}
</style>
